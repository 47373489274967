export { profileClass };

import { eventBus } from "@/eventBus";
import { store } from "@/store/store";

class profileClass {
  constructor() {
    import(/* webpackChunkName: "userClass" */ /* webpackMode: "lazy" */ "./contentClass").then((content) => {
      this.content = new content.contentClass();
    });

    eventBus.$on("list-profiles", (data) => {
      this.listProfiles(data);
    });

    eventBus.$on("changePassword", (data) => {
      this.changePassword(data);
    });

    eventBus.$on("createProfile", (data) => {
      this.createProfile(data);
    });

    eventBus.$on("editProfile", (data) => {
      this.editProfile(data);
    });

    eventBus.$on("deleteProfile", (data) => {
      this.deleteProfile(data);
    });

    eventBus.$on("fileUpload", (data) => {
      this.uploadImageFile(data);
    });

    eventBus.$on("switch-profile", (data) => {
      this.switchProfile(data);
    });

    eventBus.$on("create-ticket", (data) => {
      this.createNewTicket(data);
    });

    eventBus.$on("list-tickets", (data) => {
      this.listTicket(data);
    });

    eventBus.$on("fetch-ticket", (data) => {
      this.fetchTicket(data);
    });

    eventBus.$on("update-current-ticket", (data) => {
      this.updateCurrentTicket(data);
    });

    eventBus.$on("change-status", (data) => {
      this.changeDeviceStatus(data);
    });

    eventBus.$on("list-devices", (data) => {
      this.listDevice(data);
    });

    eventBus.$on("planList_subscriptions", (data) => {
      this.listPlansSubscriptions(data);
    });

    eventBus.$on("planList_profile", (data) => {
      this.listProfilePlans(data);
    });

    eventBus.$on("subscriptionList", (data) => {
      this.listSubscribedPlans(data);
    });

    eventBus.$on("subscribe-precheck", (data) => {
      this.subscribePrecheck(data);
    });

    eventBus.$on("subscription-update", (data) => {
      this.updateSubscription(data);
    });

    eventBus.$on("list-subscribed-plans", (data) => {
      this.listSubscribedPlans(data);
    });

    eventBus.$on("plan-subscription-precheck", (data) => {
      this.precheckSubscription(data);
    });

    eventBus.$on("list-all-bills", (data) => {
      this.listAllBills(data);
    });

    eventBus.$on("plan-coupon-precheck", (data) => {
      this.checkCouponValidity(data);
    });

    eventBus.$on("purchase-coupon-precheck", (data) => {
      this.purchaseCouponValidity(data);
    });

    eventBus.$on("create-free-subscription", (data) => {
      this.createSubscription(data);
    });
  }

  subscriberList() {
    store
      .dispatch("listSubscriber")
      .then((response) => {
        eventBus.$emit("profile-list", response.data);
      })
      .catch((error) => console.log(error));
  }

  listProfiles(payload) {
    store
      .dispatch("listProfiles")
      .then((response) => {
        if (response.data) {
          if (payload === "profileDropdown") {
            eventBus.$emit("profile-list-response", response.data);
          } else {
            eventBus.$emit("profile-list-detail-response", response.data);
          }
        }
      })
      .catch((error) => console.log(error));
  }

  changePassword(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("updateSubscriber", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          eventBus.$emit("changePasswordResponse", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  createProfile(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("createProfile", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          let data = {
            status: response.data,
            profilePayload: payload,
          };
          eventBus.$emit("createprofile-response", data);
        }
      })
      .catch((error) => console.log(error));
  }

  editProfile(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("updateSubscriber", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          let data = {
            response: response.data,
            payload: payload,
          };
          // eventBus.$emit("editprofile-response", response.data);
          eventBus.$emit("editprofile-response", data);
          eventBus.$emit("subscriberDetails");
        }
      })
      .catch((error) => console.log(error));
  }

  uploadImageFile(payload) {
    const payLoadData = payload.isTicket ? payload.params : payload;

    if (!payload.isTicket) {
      store.dispatch("fileUpload", payLoadData).then((response) => {
        if (response.data.success) {
          eventBus.$emit("file-upload-response", response.data);
        }
      });
    } else {
      store.dispatch("fileUpload", payLoadData).then((response) => {
        console.log("FILE UPLOAD ERR -->", response);

        eventBus.$emit(`file-upload-response-${payload.index}`, response.data);
      });
    }
  }

  deleteProfile(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("deleteProfile", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          let data = {
            status: response.data,
            deletePayload: payload,
          };
          eventBus.$emit("deleteprofile-response", data);
        }
      })
      .catch((error) => console.log(error));
  }

  switchProfile(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("switchProfile", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data) {
          eventBus.$emit("switchProfileResponse", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  createNewTicket(payload) {
    eventBus.$emit("loader", true);
    store
      .dispatch("createTicket", payload)
      .then((response) => {
        eventBus.$emit("loader", false);
        if (response.data.reason) {
          eventBus.$emit("create-ticket-error", response.data.reason);
        } else {
          console.log("create ticket response", response);
          eventBus.$emit("create-ticket-success");
        }
      })
      .catch((error) => console.log(error));
  }

  fetchTicket(payload) {
    store
      .dispatch("getTicket", payload)
      .then((response) => {
        eventBus.$emit("get-ticket-response", response);
      })
      .catch((error) => {
        eventBus.$emit("get-ticket-error", response);
        console.log(error);
      });
  }

  changeDeviceStatus(payload) {
    eventBus.$emit("loading", true);
    console.log("device payload", payload);
    store
      .dispatch("updateDevice", payload)
      .then((response) => {
        console.log("status response", response);
        eventBus.$emit("loading", false);
        if (response.data) {
          console.log("dfddfdf");
          this.listDevice();
        }
      })
      .catch((error) => console.log(error));
  }

  listDevice(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("deviceList", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data.reason) {
          eventBus.$emit("show-error-alert-devices", response.data.reason);
        } else {
          eventBus.$emit("list-enabled-devices", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  listTicket(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("listTickets", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data.data) {
          eventBus.$emit("ticket-response", response.data);
        } else if (response.data.reason) {
          eventBus.$emit("show-error-alert", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  listPlansSubscriptions(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("listPlans", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data) {
          eventBus.$emit("planlist-subscriptions-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  listProfilePlans(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("listPlans", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data) {
          eventBus.$emit("profile-planlist-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  listSubscribedPlans(payload) {
    store
      .dispatch("listSubscription", payload)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("subscription-response", response.data);
        }
      })
      .catch((error) => eventBus.$emit("subscription-response", error));
  }

  subscribePrecheck(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("subscriptionPrecheck", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data) {
          // eventBus.$emit("precheck-response", response.data);
          eventBus.$emit(`precheck-response-${payload.planid}`, response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  updateSubscription(payload) {
    store
      .dispatch("subscriptionUpdate", payload)
      .then((response) => {
        if (response.data) {
          console.log("RESPONSE FOR CANCEL", response.data);
          eventBus.$emit("subscriptionUpdate-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  // listSubscribedPlans(payload) {
  //   eventBus.$emit("loading", true);
  //   store
  //     .dispatch("listAllSubscription", payload)
  //     .then(res => {
  //       console.log("subscribed plans", res);
  //       eventBus.$emit("loading", false);
  //       if (res.data.totalcount > 0) {
  //         eventBus.$emit("all-subscribed-plans", res.data.data);
  //       } else if (res.data.reason) {
  //         eventBus.$emit("show-error-alert", res.data.reason);
  //       }
  //     })
  //     .catch(error => console.log(error));
  // }

  precheckSubscription(payload) {
    store
      .dispatch("subscriptionPrecheck", payload)
      .then((res) => {
        console.log("precheck output", res);
        eventBus.$emit("go-for-subscription", res);
        // if (res.data) {
        // } else if (res.data.reason) {
        //   console.log("precheckerror", res.data.reason);
        // }
      })
      .catch((error) => console.log(error));
  }

  checkCouponValidity(payload) {
    store
      .dispatch("subscriptionPrecheck", payload)
      .then((res) => {
        console.log("precheck output", res);
        eventBus.$emit("validate-coupon", res.data);
        // if (res.data) {
        // } else if (res.data.reason) {
        //   console.log("precheckerror", res.data.reason);
        // }
      })
      .catch((error) => console.log(error));
  }

  purchaseCouponValidity(payload) {
    store
      .dispatch("purchasePrecheck", payload)
      .then((res) => {
        console.log("precheck output", res);
        eventBus.$emit("validate-coupon", res.data);
        // if (res.data) {
        // } else if (res.data.reason) {
        //   console.log("precheckerror", res.data.reason);
        // }
      })
      .catch((error) => console.log(error));
  }

  createSubscription(payload) {
    eventBus.$emit("loading", true);
    store
      .dispatch("createSubscription", payload)
      .then((response) => {
        eventBus.$emit("loading", false);
        if (response.data) {
          eventBus.$emit("free-subscription-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }

  listAllBills(payload) {
    store
      .dispatch("listBill", payload)
      .then((response) => {
        console.log("bills response", response);
        if (response.data.reason) {
          eventBus.$emit("show-error-alert", response.data.reason);
        } else {
          eventBus.$emit("list-bills-response", response.data.data);
        }
      })
      .catch((error) => console.log(error));
  }

  updateCurrentTicket(payload) {
    store
      .dispatch("updateTicket", payload)
      .then((response) => {
        if (response.data) {
          eventBus.$emit("update-Ticket-response", response.data);
        }
      })
      .catch((error) => console.log(error));
  }
}
